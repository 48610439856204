import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-orientato-didattica',
  templateUrl: './orientato-didattica.component.html',
  styleUrls: ['./orientato-didattica.component.scss']
})
export class OrientatoDidatticaComponent implements OnInit {

  constructor(
    public deviceDetector:DeviceDetectorService
  ) { }

  ngOnInit(): void {
  }

}
