<div class="container-fluid container-bg">
    <div class="row custom-he">
        <div class="col-lg-6 col-wrapper-left">
            <img src="/assets/pietre-2.png" class="custom-image" alt="">
        </div>
        <div class="col-lg-6 col-wrapper">
            <h2 class="h2-1">BENVENUTI</h2>
            <br> <br> <br class="almeno992">
            <h3 style="font-weight: 300; color: wheat;">Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Maecenas egestas risus nulla, nec auctor odio
                gravida sed. Morbi tincidunt lectus lectus, sed
                faucibus neque cursus eget.
            </h3>
            <br><br class="min-992">

            <ul class="social-icons">
                <li><a style="text-decoration: none;" target="_blank"
                        href="https://www.facebook.com/WikiTarot-104956955023319"> <i
                            class="facebook-hov social-icon fa fa-facebook"></i></a></li>
                <li> <a style="text-decoration: none;" target="_blank"
                        href="https://www.youtube.com/channel/UCvr0h9vg5beyZ8t2AycZafQ"> <i
                            class="google-hov social-icon fa fa-youtube" style="font-size: 18px;"></i></a></li>
                <li> <a style="text-decoration: none;" target="_blank" href="https://www.instagram.com/wikitarot/"><i
                            class="instagram-hov social-icon fa fa-instagram"></i></a> </li>
                <!--
                    <li> <a style="text-decoration: none;" target="_blank" href=""><i
                            class="instagram-hov social-icon fa fa-twitter"></i></a> </li>
-->
            </ul>
        </div>
    </div>
</div>

<app-cielo-spirituale></app-cielo-spirituale>

<app-mondo-esoterico></app-mondo-esoterico>

<app-orientato-didattica></app-orientato-didattica>

<app-formazione-olistica></app-formazione-olistica>

<div class="bianco-mob">

</div>

<app-wikitarot></app-wikitarot>

<app-prefooter></app-prefooter>