<div class="container-fluid  pt-4 pb-4">
    <div class="row">
        <div class="col-lg-12 text-center">
            <img src="/assets/pietre-2.png" class="custom-pietre" alt="">
        </div>
        <div class="col-lg-12 text-center">
            <div class="social-wrapper">
                <ul class="social-icons">
                    <br> <br>
                    <li><a style="text-decoration: none;" target="_blank"
                            href="https://www.facebook.com/WikiTarot-104956955023319"> <i
                                class="facebook-hov social-icon fa fa-facebook"></i></a></li>
                    <li> <a style="text-decoration: none;" target="_blank"
                            href="https://www.youtube.com/channel/UCvr0h9vg5beyZ8t2AycZafQ"> <i
                                class="google-hov social-icon fa fa-youtube" style="font-size: 18px;"></i></a></li>
                    <li> <a style="text-decoration: none;" target="_blank"
                            href="https://www.instagram.com/wikitarot/"><i
                                class="instagram-hov social-icon fa fa-instagram"></i></a> </li>
<!--
                        <li> <a style="text-decoration: none;" target="_blank" href=""><i
                                class="instagram-hov social-icon fa fa-twitter"></i></a> </li>
-->
                </ul>
            </div>
        </div>
    </div>
</div>