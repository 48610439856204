import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultPageComponent } from './layout/default-page/default-page.component';
import { FooterComponent } from './layout/default-page/footer/footer.component';
import { HeaderComponent } from './layout/default-page/header/header.component';
import { FullscreenMenuComponent } from './layout/default-page/fullscreen-menu/fullscreen-menu.component';
import { HomeComponent } from './pages/home/home.component';
import { ChiSiamoComponent } from './pages/chi-siamo/chi-siamo.component';
import { CieloSpiritualeComponent } from './component/cielo-spirituale/cielo-spirituale.component';
import { MondoEsotericoComponent } from './component/mondo-esoterico/mondo-esoterico.component';
import { OrientatoDidatticaComponent } from './component/orientato-didattica/orientato-didattica.component';
import { FormazioneOlisticaComponent } from './component/formazione-olistica/formazione-olistica.component';
import { NewsletterComponent } from './component/newsletter/newsletter.component';
import { WikitarotComponent } from './component/wikitarot/wikitarot.component';
import { PrefooterComponent } from './component/prefooter/prefooter.component';

@NgModule({
  declarations: [
    AppComponent,
    DefaultPageComponent,
    FooterComponent,
    HeaderComponent,
    FullscreenMenuComponent,
    HomeComponent,
    ChiSiamoComponent,
    CieloSpiritualeComponent,
    MondoEsotericoComponent,
    OrientatoDidatticaComponent,
    FormazioneOlisticaComponent,
    NewsletterComponent,
    WikitarotComponent,
    PrefooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
