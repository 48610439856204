import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  isOpen = false;

  constructor() { }

  close() {
    this.isOpen = false;
  }

  open() {
    this.isOpen = true;
  }

  toggle() {
    if (this.isOpen == false) {
      this.isOpen = true;
    } else {
      this.isOpen = false;
    }
  }
}
