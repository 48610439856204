<div class="navbar navbar-custom navbar-expand-lg">
    <img src="/assets/logo-inline-2.png" class="custom-logo" (click)="naviga('')" alt="equinox">

    <div class="button-wrapper">
        <button class="btn btn-custom" (click)="switchMenu()"><img src="/assets/bottone-menu.svg" style="max-width: 120px;" alt="logo equinox"></button>
    </div>
    <div *ngIf="deviceDetector.isMobile() == true" class="button-wrapper">
        <button class="btn btn-custom" (click)="switchMenu()"><img src="/assets/bottone-menu.svg" style="max-width: 120px;" alt="logo equinox"></button>
    </div>
</div>  


<app-fullscreen-menu *ngIf="!!menuService.isOpen" (close)="showFullscreenMenu=false"></app-fullscreen-menu>