<div class="container-fluid bg">
    <div class="row custom-he">
        <div class="col-lg-6 my-auto col-wrapper">
            <h2 class="custom-h2">FORMAZIONE OLISTICA</h2>
            <br>
            <h3 class="custom-h3">
                Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Maecenas egestas risus nulla,
                nec auctor odio gravida sed. Morbi tincidunt
                lectus lectus, sed faucibus neque cursus eget.
                Ut sed luctus dolor, sed dictum diam. Sed ut
                justo vel lectus suscipit porttitor. Nunc
                gravida, enim ut iaculis malesuada, lorem
                enim fermentum felis, nec sodales ligula
                metus nec nulla.
            </h3>
            <br>
            <div class="wrapper-b">
                <button class="btn btn-custom-chiaro">Scopri di Più</button>
            </div>
        </div>
        <div class="col-lg-6 text-center">
            <img src="/assets/formazione-olistica.jpg" class="img-fluid custom" alt="formazione olistica equinox">
        </div>
    </div>
</div>