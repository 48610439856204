import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MenuService } from 'src/app/service/menu.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    public deviceDetector: DeviceDetectorService,
    public menuService:MenuService,
    public router:Router
    ) { }

  switchMenu() {
    this.menuService.toggle();
  }

  naviga(pagina){
    this.router.navigate([pagina])
  }

  ngOnInit(): void {
  }

}
