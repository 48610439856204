<div class="container-fluid bg">
    <div class="row custom-he">
        <div class="col-lg-7 bg-ima">

        </div>
        <div class="col-lg-5 my-auto col-wrapper">
            <h2 class="custom-h2">WIKITAROT</h2>
            <br>
            <h3 class="custom-h3">
                WikiTarot non è un semplice sito di cartomanzia, ma un vero e proprio portale esoterico in grado di
                abbracciare e fondere insieme varie discipline divinatorie, nelle diverse fasi della vostra vita, in
                base all`esperienza che state vivendo in questo momento. <br> <br>

                In tale prospettiva, desideriamo accompagnarvi nel percorso più adatto a risolvere i vostri problemi, in
                ogni ambito: amore, lavoro, soldi, realizzazione personale, etc...
            </h3>
            <br>
            <div class="wrapper-b mb-4">
                <a class="btn btn-custom-chiaro" target="_blank" href="https://wikitarot.it/">Scopri di Più</a>
            </div>
        </div>

    </div>
</div>