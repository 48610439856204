<div *ngIf="menuService.isOpen == true" class="menu-fullscreen animated fadeIn faster" (click)="menuService.open()">
    <div class="close-btn" style="z-index: 9000000;" (click)="menuService.close()">
        <i class="fa fa-close" style="margin-right: 22px; cursor: pointer;"></i>
    </div>

    <div class="row" style="height: 100%;">
        <div class="col-lg-3"></div>
        <div class="col-lg-6 col-md-12 text-center my-auto">
            <img src="/assets/logo-inline-2.png" class="mb-4" style="max-width: 200px;" alt="">
            <div class="row">
                <div class="col-lg-12 custom-link">
                    <a class="custom" href="#">
                        <h2 style="font-weight: 600;margin-bottom: 0px;">HOME</h2>
                    </a>
                </div>
                <div class="col-lg-12 custom-link">
                    <a class="custom" href="/chi-siamo">
                        <h2  style="font-weight: 600;margin-bottom: 0px;">CHI SIAMO</h2>
                    </a>
                </div>
            </div>
        </div>
        <div class="col-lg-3"></div>
    </div>
</div>