import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-formazione-olistica',
  templateUrl: './formazione-olistica.component.html',
  styleUrls: ['./formazione-olistica.component.scss']
})
export class FormazioneOlisticaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
