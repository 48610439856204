<div class="container-fluid pt-4 pb-4">
    <div class="container">
        <div class="row text-center">
            <div class="col-lg-12">
                <h2 class="custom-h2">ORIENTATO DIDATTICA</h2>
                <br> <br>
                <h3 class="custom-h3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas egestas risus
                    nulla, nec
                    auctor odio gravida sed. Morbi tincidunt lectus lectus, sed faucibus neque cursus eget.</h3>
                <br> <br>
                <button class="btn btn-custom-scuro mb-3">Scopri di Più</button>
            </div>
            <div class="col-lg-12 text-center">
                <img *ngIf="deviceDetector.isMobile() == false" src="/assets/immagine-tripla.jpg" class="img-fluid"
                    alt="orientato Didattica">
                <img *ngIf="deviceDetector.isMobile() == true" src="/assets/immagine-tripla-mob.jpg" class="img-fluid"
                    alt="orientamento Didattica">
            </div>
        </div>
    </div>
</div>