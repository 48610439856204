import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cielo-spirituale',
  templateUrl: './cielo-spirituale.component.html',
  styleUrls: ['./cielo-spirituale.component.scss']
})
export class CieloSpiritualeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
